:root {
    --font-family: 'Kanit', sans-serif;

    --h1-font-size: 5rem;
    --p-font-size: 1.1rem;

   @media screen and (max-width: 50rem) {
        --h1-font-size: 3rem;
        --p-font-size: 1rem;
    }
}

html,
body {
    margin: 0;
}

h1 {
    font-family: var(--font-family);
    font-weight: 900;
    font-size: var(--h1-font-size);

    margin: 0;
}

p {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: var(--p-font-size);

    margin: 0;

}

main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border: 2vw solid black;
    padding: 10vw;
    width: 100vw;
    min-height: 100vh;

    box-shadow: inset 0px 0px 25px 7px #000000;
    box-sizing: border-box;
}

section {
    &:not(:last-child) {
        margin-bottom: 3rem;

        @media (max-width: 50rem) {
            margin-bottom: 1.7rem;
        }
    }
}

ul {
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0;
    padding: 0;

    @media (max-width: 50rem) {
        flex-direction: column;
    }
}

li {
    list-style: none;
    
    &:not(:last-child) {
        margin-right: 3rem;

        @media (max-width: 50rem) {
            margin-right: 0;
            margin-bottom: .7rem;
        }
    }
    
}

a {
    display: block;
    min-width: 80px;

    position: relative;
    font-family: var(--font-family);
    font-weight: 900;
    font-style: italic;
    text-decoration: none;
    color: inherit;

    cursor: pointer;

    &:hover::after {
        transform: scaleX(1);
    }

    &:after {
        content: '';
        position: absolute;
        bottom: -3px;
        left: 0;
        width: 100%;
        height: 2px;
        transform: scaleX(0);

        background-color: black;
        transition: transform .3s ease;
    }
}


.frame {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;

    position: relative;
    border: 10px solid black;
    border-radius: .5rem;
    padding: 10vw;
    
    width: 90vw;
    height: 90vh;
    
    box-sizing: border-box;
    box-shadow: inset 0px 0px 13px 2px #000000;
    
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border-radius: .5rem;
        width: 100%;
        height: 100%;

        box-shadow: 0 0 16px 10px #000;

        z-index: -1;
    }
}

.container {
    display: flex;
    flex-direction: column;

    text-align: center;
}