:root {
  --font-family: "Kanit", sans-serif;
  --h1-font-size: 5rem;
  --p-font-size: 1.1rem;
}

@media screen and (max-width: 50rem) {
  :root {
    --h1-font-size: 3rem;
    --p-font-size: 1rem;
  }
}

html, body {
  margin: 0;
}

h1 {
  font-family: var(--font-family);
  font-weight: 900;
  font-size: var(--h1-font-size);
  margin: 0;
}

p {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: var(--p-font-size);
  margin: 0;
}

main {
  width: 100vw;
  min-height: 100vh;
  box-sizing: border-box;
  border: 2vw solid #000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10vw;
  display: flex;
  box-shadow: inset 0 0 25px 7px #000;
}

section:not(:last-child) {
  margin-bottom: 3rem;
}

@media (max-width: 50rem) {
  section:not(:last-child) {
    margin-bottom: 1.7rem;
  }
}

ul {
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
}

@media (max-width: 50rem) {
  ul {
    flex-direction: column;
  }
}

li {
  list-style: none;
}

li:not(:last-child) {
  margin-right: 3rem;
}

@media (max-width: 50rem) {
  li:not(:last-child) {
    margin-bottom: .7rem;
    margin-right: 0;
  }
}

a {
  min-width: 80px;
  font-family: var(--font-family);
  color: inherit;
  cursor: pointer;
  font-style: italic;
  font-weight: 900;
  text-decoration: none;
  display: block;
  position: relative;
}

a:hover:after {
  transform: scaleX(1);
}

a:after {
  content: "";
  width: 100%;
  height: 2px;
  background-color: #000;
  transition: transform .3s;
  position: absolute;
  bottom: -3px;
  left: 0;
  transform: scaleX(0);
}

.frame {
  width: 90vw;
  height: 90vh;
  box-sizing: border-box;
  border: 10px solid #000;
  border-radius: .5rem;
  flex-direction: column;
  place-content: center;
  padding: 10vw;
  display: flex;
  position: relative;
  box-shadow: inset 0 0 13px 2px #000;
}

.frame:after {
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: .5rem;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 16px 10px #000;
}

.container {
  text-align: center;
  flex-direction: column;
  display: flex;
}

/*# sourceMappingURL=index.10e7b1b5.css.map */
